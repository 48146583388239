.cb-signin-logo a {
	color: rgb(15, 23, 42);
}

.login-control-container {
	min-width: 280px;
}

@media screen and (min-width: 992px) {
	.cb-signin-logo {
		display: none;
	}
}